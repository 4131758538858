const getUpsertOfferSpecsParams = (specs) => {
  return {
    cups: specs.cups,
    lat: specs.coordinates.latitude.toString(),
    long: specs.coordinates.longitude.toString(),
    tension: getTension(),
    consumption: specs.consumption,
    extraAdditionalCost: specs.extraAdditionalCost,
    panelsModel: specs.panelsModel,
    panels1: getTotalPanels(specs, 1),
    shadedPanels1: specs.shadedPanels1,
    azimuth1: specs.azimuth1,
    tilt1: specs.tilt1,
    ...(getTotalPanels(specs, 2) && {
      panels2: getTotalPanels(specs, 2),
    }),
    onlySolar: specs.onlySolar || false,
    shadedPanels2: specs.shadedPanels2,
    azimuth2: specs.azimuth2,
    tilt2: specs.tilt2,
    sharedCups: specs.sharedCups ? getSharedCups() : {},
    assets: specs.assets ? getAssets() : [],
    discounts: specs.discounts,
    wantsEnergyEfficiencyCertificate:
      specs.wantsEnergyEfficiencyCertificate || false,
    promotions: specs.promotions ? getPromotions() : [],
  }

  function getTotalPanels(specs, panelNumber) {
    return specs[`panels${panelNumber}`] + specs[`shadedPanels${panelNumber}`]
  }

  function getTension() {
    return `${specs.installationType}x${specs.tension}`
  }

  function getSharedCups() {
    return Object.entries(specs.sharedCups)?.map((item) => {
      return {
        cups: item[0],
        monthlyInvoice: item[1].monthlyInvoice,
      }
    })
  }

  function getAssets() {
    return Object.values(specs.assets)?.map((item) => {
      return {
        id: item.id,
        amount: item.amount,
      }
    })
  }

  function getPromotions() {
    return Object.values(specs.promotions)?.map((item) => {
      return {
        name: item.name,
      }
    })
  }
}

export { getUpsertOfferSpecsParams }
